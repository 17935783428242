html,
body
{
	margin: 0;
	padding: 0;
	height: 100%;
}

.sentencePage
{
	margin-top: 51px;

	.sentenceInput {
		padding-top:7px;
	
		form
		{
			margin: 10px;
			padding: 10px;
			border: 1px solid silver;
		}
	
		.inputField
		{
			padding-bottom:15px;
		}
	
		div
		{
			width: 100%;
	
			button
			{
				padding: 3px;
				margin-right: 10px;
				width: 100px;
			}
		}
	}
	
	.sentences
	{
		border-spacing: 0px;
	}

	div
	{
		.sentenceRow
		{
			border-bottom: 1px solid silver;
			padding: 4px 7px;
		}
	
		.selected
		{
			background-color: ghostwhite;
		}
	
		.character,
		.simplified,
		.traditional
		{
			font-family: "汉鼎简中楷", "KaiTi";
			font-size: 20px;
			font-weight: normal;
	
			.blue { color:blue; }
			.red { color: red; }
			.green { color: green; }
			.purple { color: purple; }
			.ashgray { color: ashgray; }
		}
	
		.pinyin
		{
			font-family: "Arial Unicode MS", Arial, sans-serif;
			font-size: 14px;
			font-weight: bold;
			padding-top: 2px;
			padding-bottom: 1px;
			color: rgb(70, 70, 70);
		}
	
		.english
		{
			font-family: "Arial Unicode MS", Arial, sans-serif;
			font-size: 14px;
			font-weight: normal;
			vertical-align: text-top;
			color: rgb(104, 103, 103);
		}
	}
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media all and (orientation:portrait)
{

}
  
@media all and (orientation:landscape)
{
	// override font sizes for landscape view.
	.dictionaryPage div
	{	
		.character { font-size: 18px; }
		.pinyin { font-size: 12px; }
		.english { font-size: 10px;	}
	}
}